import {useProduct} from "../../context/ProductContext";
import React from "react";
import {useCompare} from "../../hooks/useCompare";
import {T} from "@deity-io/falcon-i18n";

const AddToCompare = () => {
    const product = useProduct();
    const {addToCompare, compareProducts, removeCompare} = useCompare();
    const isInCompare = compareProducts.find((_product) => _product.sku == product.sku) !== undefined

    const clickHandler = () => {
        const _product = {
            id: product.id,
            sku: product.sku,
            uid: product.uid,
            thumbnail:product.thumbnailImage.large_image_url,
            name:product.name,
            canonical_url: product.canonical_url,
        }
        if(isInCompare){
            removeCompare(_product.sku)
        }else {
            addToCompare(_product)
        }
    }



    if (compareProducts.length >= 3 && !isInCompare) {
        return null
    }

    return (
        <span href="#" className="product-item__compare-link">
            <div className="product-item__compare-icon-wrap">
              <label><input type='checkbox' checked={isInCompare} onClick={clickHandler}/>  <T id="Compare"/></label>
            </div>
        </span>
    );
}

export default AddToCompare;
