import gql from "graphql-tag";

export const STORE_LOCATIONS = gql`
  query ($cart_id: String){
    storeLocations (cart_id: $cart_id){
      address
      city
      country
      openingDays: opening_days
      phoneNumber: phone_number
      state
      storeName: store_name
      storepickupId: storepickup_id
      workingHours: working_hours
      zipcode
      additionalInformation:additional_information
    }
  }
`;

export const GET_PRODUCT_SLIDER = gql`
    query productsSlider($condition: String!, $pageSize: Int, $sort: String = "position_by_sku", $category: Int) {
        productsSlider(condition: $condition, pageSize: $pageSize, sort: $sort, category: $category) {
            __typename
            name
            sku
            uid
            id
            brand
            emlabels {
                label_text
                attribute_code
                priority
                product_position
                category_position
                label_image
                href
            }
            canonical_url
            advanced_product_inventory {
                __typename
                is_qty_decimal
                use_config_enable_qty_inc
                enable_qty_increments
                use_config_qty_increments
                qty_increments
                use_config_min_sale_qty
                min_sale_qty
                unit
              }
            categories {
                __typename
                name
                canonical_url
                breadcrumbs {
                    category_id
                    category_name
                    category_level
                    category_url_key
                }
            }
            url_key
            is_saleable
            is_on_order_only
            lifetime_status
            gallery {
                disabled
                disabled_default
                file
                id
                label
                label_default
                large_image_url
                media_type
                medium_image_url
                hover_image_url
                position
                position_default
                row_id
                small_image_url
                url
                value_id
                is_hover
                is_thumbnail
                is_inspiration
            }
            guarantees {
                guarantee_description
                guarantee_img_url
            }
            price_last_30
            prices {
                amount
                unit
                bg_color
                label
                old_amount
                percentage
                price_type
                promotion_start
                promotion_end
                txt_color
                currency_symbol
                description
            }
        }
    }
`;
