import React, { useEffect, useState } from "react";
import loadable from '@loadable/component';
import readConfig from '../../../utils/readClientConfiguration';
import productionConfiguration from '../../../../config/default.json';
import { useLocation } from 'react-router-dom';
import { BuildAbsoluteUrl } from "../../../utils/urlBuilder";
const Helmet = loadable(() => import('react-helmet'));
var tradedoublerContainerTagId = readConfig(productionConfiguration, 'tradedoublerContainerTagId');
var currency = readConfig(productionConfiguration, 'currencyCode');
const country = readConfig(productionConfiguration, 'countryCode');
const isRs = country.includes('RS');

export default function TradedoublerContainerTag({ cart, product, page, products, orderNumber, currencyCode }) {

    if(!isRs){
        return null
    }
    
    var config = {}
    if (product) {
        const [price] = product.prices;
        const location = useLocation();
        const firstImage = product.gallery && product.gallery.length && product.gallery.reduce((prev, curr) => (prev.position < curr.position ? prev : curr));
        const firstImageUrl =
            firstImage && (firstImage.large_image_url || firstImage.medium_image_url || firstImage.small_imgage_url);

        config.productId = product.sku;
        let productCategory = product.categories.pop();
        if (productCategory) {
            config.category = productCategory.name;
        }
        config.productName = product.name;
        config.productDescription = product.description.html.replace(/<br>/g, ' ').replace(/(<([^>]+)>)/gi, ' ');
        if (price) {
            config.price = price.amount;
        }
        config.currency = currency;
        config.url = BuildAbsoluteUrl(location.pathname);
        config.imageUrl = firstImageUrl;
    }
    if (cart && cart.items) {

        var products = cart.items.map(x => {
            const product = {};
            product.id = x.product.sku;
            product.price = x.product.prices[0].amount;
            product.currency = currencyCode;
            product.name = x.product.name;
            product.qty = `${x.quantity}`;
            return product;
        })
        config.products = products;
        if (page == "purchase") {
            config.orderId = orderNumber;
            config.orderValue = `${cart.prices.subtotal_including_tax.value}`;
        }
    }
    config.pageType = page;
    var tdConf = JSON.stringify(config);

    return <Helmet>(
        <script type="text/javascript">{`
function setCookie(name, value, expires, path, domain, secure) {
    var today = new Date();
    today.setTime( today.getTime() );
    if ( expires ){
        expires = expires * 1000 * 60 * 60 * 24;
    }
    var expires_date = new Date( today.getTime() + (expires) );
    document.cookie= name + "=" + escape(value) +
    ((expires) ? "; expires=" + expires_date.toGMTString() : "") +
    ((path) ? "; path=" + path : "") +
    ((domain) ? "; domain=" + domain : "") +
    ((secure) ? "; secure" : "");
}
function getVar(name) {
    get_string = document.location.search;
    return_value = '';
    do {
        name_index = get_string.indexOf(name + '=');
        if(name_index != -1) {
            get_string = get_string.substr(name_index + name.length + 1, get_string.length - name_index);
            end_of_value = get_string.indexOf('&');
            if(end_of_value != -1) {
                value = get_string.substr(0, end_of_value);
            } else {
                value = get_string;
            }
            if(return_value == '' || value == '') {
                return_value += value;
            } else {
                return_value += ', ' + value;
            }
        }
    } while(name_index != -1) {
        space = return_value.indexOf('+');
    }
    while(space != -1){
        return_value = return_value.substr(0, space) + ' ' + return_value.substr(space + 1, return_value.length);
        space = return_value.indexOf('+');
    }
    return(return_value);
}
var mytduid = getVar('tduid');
if (mytduid != '') {
    setCookie('TRADEDOUBLER', mytduid, 365, '/', window.location.hostname);
}


var TDConf = TDConf || {};
TDConf.Config = ${tdConf}

`}
        </script>
        <script type="text/javascript" src={`//wrap.tradedoubler.com/wrap?id=${tradedoublerContainerTagId}`}></script>)
    </Helmet>
}
