/* eslint-disable camelcase */
import {useQuery} from '@apollo/react-hooks';
import ScrollToTop from '@deity-io/falcon-client/src/components/ScrollToTop';
import {LocaleProvider, OnlineStatus, SearchProvider} from '@deity-io/falcon-ecommerce-uikit';
import {I18n} from '@deity-io/falcon-i18n';
import {ThemeEditorState} from '@deity-io/falcon-theme-editor';
import {ThemeProvider} from '@deity-io/falcon-ui';
import moment from 'moment';
import React from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
// TODO: this is a security risk, should not be exposed on frontend
import appConfiguration from '../config/default.json';
import GoogleTagManager from './components/GoogleTagManager';

import './App.scss';
import {GET_BACKEND_CONFIG} from './commonQueries';
import {ErrorBoundary} from './components/ErrorBoundary';
import PreConnect from './components/Header/PreConnect';
import loadable from './components/loadable';
import ScrollToTopOnNavigate from './components/ScrollToTopOnNavigate/ScrollToTopOnNavigate';
import {CartContextProvider} from './context/CartContext';
import {PopupProvider} from './context/PopupContext';
import {ContextProvider} from './context/store';
import {UserProvider} from './context/UserContext';
import {QuarticonContextProvider} from './context/QuarticonContext';
import {deityGreenTheme, globalCss} from './theme';
import readConfig from './utils/readClientConfiguration.js';
import SubscriberConfirm from "./screens/Newsletter/SubscriberConfirm";
import NotFoundPage from "./routes/NotFoundPage/NotFoundPage";
import TradedoublerContainerTag from './components/Tracking/Tradedoubler/TradedoublerContainerTag';
import HeadMetaTags from "./components/HeadMetaTags";
import SearchResults from "./screens/SearchResults/SearchResults";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {ListingContextProvider} from "./context/ListingContext";
import Media from "./screens/Media";
import {SchemaProvider} from "./context/shemaContext";
import CompareBlock from "./components/Compare/CompareBlock";
import {CompareProvider} from "./hooks/useCompare";
import Compare from "./components/Compare/Compare";

const ServiceWorkerRegistration = loadable(() => import('./utils/registerServiceWorker'), {ssr: false});


loadable(() => import('apollo-client'));

const PrivateRoute = loadable(() => import('./utils/privateRoute'));
const DynamicRoute = loadable(() => import('./routes/DynamicRoute'));

const Login = loadable(() => import('./screens/Login/Login'));
const Registration = loadable(() => import('./screens/Registration/Registration'), {ssr: false});
const GoogleAnalyticsRoute = loadable(() => import('./routes/GoogleAnalyticsRoute'), {ssr: false});
const PersonalArea = loadable(() => import('./screens/PersonalAreaScreen/PersonalArea'));
const Blog = loadable(() => import('./screens/Blog/Blog'));
const BlogDetails = loadable(() => import('./screens/Blog/BlogDetails/BlogDetails'));
const Cart = loadable(() => import('./screens/Cart/Cart'));
const Checkout = loadable(() => import('./screens/Checkout/Checkout'), {ssr: false});
const CheckoutSuccess = loadable(() => import('./screens/CheckoutSuccess'), {ssr: false});
const News = loadable(() => import('./screens/News/News'));
const NewsDetails = loadable(() => import('./screens/News/NewsDetails/NewsDetails'));
const RequestReset = loadable(() => import('./screens/Login/PasswordReset/RequestReset'));
const ResetPassword = loadable(() => import('./screens/Login/PasswordReset/ResetPassword'));
const LogoutSuccess = loadable(() => import('./screens/Logout/LogoutSuccess'));
const SalesAndDiscounts = loadable(() => import('./screens/SalesAndDiscounts/SalesAndDiscounts'));
const Header = loadable(() => import('./components/Header/Header'));
const Footer = loadable(() => import('./components/Footer/Footer'));
const NewsletterSlider = loadable(() => import('./components/NewsletterSlider/NewsletterSlider'));
const NewsletterBanner = loadable(() => import('./components/NewsletterMobileBanner/NewsletterMobileBanner'));
const ProductAlertSubscribe = loadable(() => import('./components/ProductAlert/ProductAlertSubscribe'), {ssr: false});
const ProductAlertUnsubscribe = loadable(() => import('./components/ProductAlert/ProductAlertUnsubscribe'), {
    ssr: false,
});
const ProductAlertUnsubscribeAll = loadable(() => import('./components/ProductAlert/ProductAlertUnsubscribeAll'), {
    ssr: false,
});
const CheckoutPaymentSuccess = loadable(() => import('./screens/Checkout/CheckoutPaymentSuccess'), {ssr: false});
const CheckoutPaymentCancel = loadable(() => import('./screens/Checkout/CheckoutPaymentCancel'), {ssr: false});

const countryCode = readConfig(appConfiguration, 'countryCode');

const Wishlist = loadable(() => import('./components/Wishlist/Wishlist'));
moment.locale(countryCode.substring(0, 2));

function getProps() {
    try {
        if (
            typeof window !== 'undefined' &&
            // eslint-disable-next-line no-underscore-dangle
            window.__APOLLO_CLIENT__ !== undefined &&
            // eslint-disable-next-line no-undef
            __APOLLO_CLIENT__.cache.data.get('props') !== undefined
        ) {
            // eslint-disable-next-line no-undef,no-underscore-dangle
            return window.__APOLLO_CLIENT__.cache.data.get('props');
        }
    } catch (ex) {
        console.error(ex);
    }
    return undefined;
}

const GlobalProviders = ({children}) => {
    return (
        <SchemaProvider>
            <UserProvider>
                <ListingContextProvider>
                    <ContextProvider>
                        <CartContextProvider>
                            <QuarticonContextProvider>
                                <CompareProvider>
                                <PopupProvider>{children}</PopupProvider>
                                </CompareProvider>
                            </QuarticonContextProvider>
                        </CartContextProvider>
                    </ContextProvider>
                </ListingContextProvider>
            </UserProvider>
        </SchemaProvider>
    );
};

const HealthCheckRoute = () => {
    const {data: backendConfig} = useQuery(GET_BACKEND_CONFIG, {
        fetchPolicy: 'cache-first',
    });

    if (backendConfig) {
        return <>OK</>;
    }

    return <>Error</>;
};

const readBackendConfig = (config, loading) => {
    if (loading) {
        return {};
    }

    if (config && config.configProvider) {
        return config.configProvider
    }

    return {};
}

let configuredProps;
const App = ({configurableProps}) => {
    const {pathname} = useLocation();
    const {data: backendConfig, loading: backendConfigLoading} = useQuery(GET_BACKEND_CONFIG);

    if (!configurableProps) {
        configuredProps = getProps();
    } else {
        configuredProps = configurableProps;
    }

    const {gtm_id, gtm_is_active, analytics_id, google_client_id} = readBackendConfig(
        backendConfig,
        backendConfigLoading,
    );


    const showHeaderFooter = (!pathname.includes('checkout') || pathname.includes('success')) && !configuredProps;
    const showNewsletterBanner = !pathname.includes('account') && !pathname.includes('checkout');
    const isHealthCheck = pathname.indexOf('health-check') !== -1;

    if (isHealthCheck) {
        return <HealthCheckRoute/>;
    }

    return (
        <>
            <ServiceWorkerRegistration/>
            <LocaleProvider>
                {gtm_id && gtm_is_active === '1' && <GoogleTagManager gtmId={gtm_id}/>}
                {analytics_id && gtm_is_active === '1' && <GoogleAnalyticsRoute trackerId={analytics_id}/>}
                <ScrollToTopOnNavigate/>
                <GoogleOAuthProvider clientId={google_client_id}>
                    <GlobalProviders>
                        <ThemeEditorState initial={deityGreenTheme}>
                            {props => (
                                <SearchProvider>
                                    <ThemeProvider theme={props.theme} globalCss={globalCss}>
                                        {showHeaderFooter && <Header/>}
                                        <HeadMetaTags/>
                                        <TradedoublerContainerTag page="other"></TradedoublerContainerTag>
                                        <PreConnect/>
                                        <div style={{margin: '0 auto', padding: '0 15px'}}>
                                            <NewsletterSlider/>
                                            <ScrollToTop/>
                                            {/* // TODO: translation & test */}
                                            <OnlineStatus>{({isOnline}) => !isOnline &&
                                                <p>you are offline.</p>}</OnlineStatus>
                                            <ErrorBoundary>
                                                <I18n>
                                                    {t => (
                                                        <Switch>
                                                            <Route path={["/image/media/","/media/"]}
                                                                   component={Media}/>
                                                            <Route exact path="/catalogsearch/result"
                                                                   component={SearchResults}
                                                            />
                                                            <Route exact path="/checkout/payment/success"
                                                                   component={CheckoutPaymentSuccess}/>
                                                            <Route exact path="/checkout/payment/cancel"
                                                                   component={CheckoutPaymentCancel}/>
                                                            <Route exact path="/404" component={NotFoundPage}/>
                                                            <Route exact path="/compare" component={Compare}/>
                                                            <Route exact path="/health-check" component={HealthCheckRoute}/>
                                                            <Route exact path="/akcije-i-popusti.html"
                                                                   component={SalesAndDiscounts}/>
                                                            <Route exact path="/blog/" component={Blog}/>
                                                            <Route exact path="/blog/tag/:tagId" component={Blog}/>
                                                            <Route exact path="/blog/:blogId" component={BlogDetails}/>
                                                            <Route exact path={`/${t('news.newsLink')}/`} component={News}/>
                                                            <Route path={`/${t('news.newsLink')}/:newsId/`}
                                                                   component={NewsDetails}/>
                                                            <Route exact path="/checkout/cart/" component={Cart}/>
                                                            <Route exact path="/checkout/" component={Checkout}/>
                                                            <Route exact path="/checkout/success"
                                                                   component={CheckoutSuccess}/>
                                                            <Route exact path="/customer/registration/"
                                                                   component={Registration}/>
                                                            <Route exact path="/customer/login" component={Login}/>
                                                            <Route exact path="/customer/account/forgotpassword/"
                                                                   component={RequestReset}/>
                                                            <Route exact path="/customer/account/createpassword/"
                                                                   component={ResetPassword}/>
                                                            <Route exact path="/productalert/add/:productId/:productUrl"
                                                                   component={ProductAlertSubscribe}/>
                                                            <Route exact
                                                                   path="/productalert/unsubscribe/stock/product/:productId/"
                                                                   component={ProductAlertUnsubscribe}/>
                                                            <Route exact path="/productalert/unsubscribe/stockAll/"
                                                                   component={ProductAlertUnsubscribeAll}/>
                                                            <Route exact path="/customer/account/logoutSuccess/"
                                                                   component={LogoutSuccess}/>
                                                            <Route exact
                                                                   path="/newsletter/subscriber/confirm/id/:confirmId/code/:confirmCode"
                                                                   component={SubscriberConfirm}/>
                                                            <Route exact path="/wishlist/:sharingCode"
                                                                   component={Wishlist}/>
                                                            <PrivateRoute path="/customer/account">
                                                                <PersonalArea/>
                                                            </PrivateRoute>
                                                            <DynamicRoute trackerId={analytics_id}/>
                                                        </Switch>
                                                    )}
                                                </I18n>
                                            </ErrorBoundary>
                                            {showNewsletterBanner && <NewsletterBanner/>}
                                            {showHeaderFooter && <Footer/>}
                                        </div>

                                    </ThemeProvider>
                                    <CompareBlock/>
                                </SearchProvider>
                            )}
                        </ThemeEditorState>
                    </GlobalProviders>
                </GoogleOAuthProvider>
            </LocaleProvider>
        </>
    );
};

export default App;
