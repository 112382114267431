import React, {createContext} from 'react';
import {useQuery} from "@apollo/react-hooks";
import {INTROSPECTION_QUERY} from "../routes/routeQuery";

const SchemaContext = createContext();

function SchemaProvider({children}) {
    const {loading, error, data} = useQuery(INTROSPECTION_QUERY);
    if (loading) return <p>Ładowanie...</p>;
    if (error) return <p>Błąd: {error.message}</p>;

    const schema = data.__schema;

    const getAttribute=(field)=>{
        if (field === "attribute_set_id") {
            return ""
        }
        // Get products
        let products = schema.queryType.fields.find(({name}) => name === "products");
        console.log('______',products)
        let filter = products.args.find(({name}) => name === "filter");

        if (!filter) {
            return "";
        }
        // Determine the type
        let {type} = filter.type.inputFields.find(({name}) => name === field) || {};

        // So that it doesn't yell
        if (!type) {
            return "";
        }
        // Get the name
        return type.name;
    }

    const getFilterType = (field) => {
        if (field === "attribute_set_id") {
            return ""
        }
        // Get products
        let products = schema.queryType.fields.find(({name}) => name === "products");

        // Extract the filter
        let filter = products.args.find(({name}) => name === "filter");

        if (!filter) {
            return "";
        }
        // Determine the type
        let {type} = filter.type.inputFields.find(({name}) => name === field) || {};

        // So that it doesn't yell
        if (!type) {
            return "";
        }
        // Get the name
        return type.name;
    }

    return (
        <SchemaContext.Provider value={{getFilterType, getAttribute, schema}} >
            {children}
        </SchemaContext.Provider>
    );
}

export { SchemaProvider, SchemaContext };
