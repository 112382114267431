import {useEffect, useState} from 'react';
import {Config, Recommendation, Track} from 'qon-connection';
import {useLazyQuery} from '@apollo/react-hooks';
import {GET_PRODUCT_SLIDER} from '../queries/misc';
import readConfig from "../utils/readClientConfiguration";
import appConfiguration from "../../config/default.json";

function getConditions(skuList) {
  return `{\"1\":{\"aggregator\":\"all\",\"new_child\":\"\",\"type\":\"Smile\\\\ElasticsuiteVirtualCategory\\\\Model\\\\Rule\\\\WidgetCondition\\\\Combine\",\"value\":\"1\"},\"1--1\":{\"operator\":\"()\",\"type\":\"Smile\\\\ElasticsuiteVirtualCategory\\\\Model\\\\Rule\\\\WidgetCondition\\\\Product\",\"attribute\":\"sku\",\"value\":\"${skuList}\"}}`;
}

function getSkuList(products) {
  return products && products.map(x => x._id.trim()).join(',');
}

export const sendProductsQuarticon = (sku, tracker) => {
  if (!tracker) {
    return null;
  }
  return tracker.sendProductView({ id: sku });
};

const atob = base64 => {
  if (!base64) return null;
  return Buffer.from(base64, 'base64').toString('binary');
};

export const sendTransactionQuarticon = (product, tracker) => {
  if (!tracker) {
    return null;
  }
  const { items, reserved_order_id } = product || {};

  const transaction = {
    transactionId: reserved_order_id,
    basket:
      items &&
      items.map(x => {
        return {
          productId: x.product.sku,
          price: x.product.prices[0].amount,
          quantity: x.quantity,
        };
      }),
  };
  return tracker.sendTransaction(transaction);
};

export const useRecommendationsQuarticon = (sku, type, categoryId) => {

  const [recomendations, setRecomendations] = useState([]);

  const [productsFromQuarticon, setProductsFromQuarticon] = useState([]);
  const [query, {data, loading}] = useLazyQuery(GET_PRODUCT_SLIDER, {
    onCompleted: (data) => {
      setRecomendations(data.productsSlider);
    }
  });
  const quarticonConfig = readConfig(appConfiguration, 'quarticon');

  const config = quarticonConfig && quarticonConfig.is_active &&
      new Config.Config({customerSymbol: quarticonConfig.customer_symbol, host: quarticonConfig.host_url});

  const tracker = config && new Track.Tracker(config);
  const recommendationEngine = config && new Recommendation.Recommendation(config);

  let filter = [];
  if (categoryId) {
    filter = [Recommendation.FilterApi.category(atob(categoryId))];
  } else if (sku) {
    filter = [Recommendation.FilterApi.product(sku)];
  } else {
    filter = [];
  }

  useEffect(() => {
    async function getData() {
      const widgetData = {
        placementId: quarticonConfig.widget_ids[type],
        filters: filter,
      };
      const products = await recommendationEngine.getRecommendation(widgetData);
      setProductsFromQuarticon(products);
      const skuList = getSkuList(products);
      query({variables: {condition: getConditions(skuList)}});
    }

    if (recommendationEngine) {
      getData().catch(console.error);
    }
  }, [categoryId, type]);
  return {recomendations: recomendations, loading};
};
